import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { HomeLink } from '../Navigation/styles'
import { Container, LogoContainer } from './styles'

const BasicNavigation = () => {
  const { logoBlue } = useStaticQuery(graphql`
    query BasicNavImagesQuery {
      logoBlue: file(relativePath: { eq: "skylight-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  return (
    <Container>
      <HomeLink to="/">
        <LogoContainer>
          <GatsbyImage image={logoBlue.childImageSharp.gatsbyImageData} alt="Skylight Logo" />
        </LogoContainer>
      </HomeLink>
    </Container>
  )
}

export default BasicNavigation
