import React, { useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import { GatsbyImage } from 'gatsby-plugin-image'
import ConditionalCTA from 'src/components/ConditionalCTA'

import {
  PLUS_BUY_LINK,
  PLUS_PRODUCT_HANDLE,
  COUNTRIES,
  IS_FOREIGN_COUNTRY,
  SUPPORT_LINK,
  BUY_LINK,
  CAL_BUY_LINK,
  IN_CA,
  IN_AU,
  IN_UK,
  LOCALIZATION,
} from 'src/utils/constants'
import Flag from 'react-world-flags'
import {
  Container,
  NavContainer,
  NavLink,
  NavA,
  NavAProduct,
  HomeLink,
  CartLink,
  DesktopCartLink,
  IconContainer,
  NavBuyNowLink,
  NavBuyNowAnchor,
  MobileNavBuyNowLink,
  LogoContainer,
  MobileNavLinkContainer,
  MobileNavLink,
  QuantityText,
  QuantityTextMobile,
  MobileNavLinkA,
  MobileCartView,
  ButtonContainer,
  Text,
  MobileLink,
  MobileProduct,
  ProductsLink,
  HideOnMobile,
  ProductContainer,
  ProductCard,
  Badge,
} from './styles'
import StoreContext from '../../context/StoreContext'

const inUS = !IS_FOREIGN_COUNTRY

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = useMemo(() => items.reduce((acc, item) => acc + item.quantity, 0), [items])
  return [total !== 0, total]
}

const Navigation = ({ buyLink, page, buyAnalyticsEvent, handle }) => {
  const { t } = useTranslation('common')
  const [showMenu, setShowMenu] = useState(false)
  const [showProducts, setShowProducts] = useState(false)
  const [showCountries, setShowCountries] = useState(false)
  const [hasItemsInCart, numItems] = useQuantity()

  let supportLink
  if (handle?.includes('calendar') || page?.includes('calendar')) {
    supportLink = SUPPORT_LINK.calendar
  } else {
    supportLink = SUPPORT_LINK[LOCALIZATION]
  }

  const {
    logoBlue,
    mobileLogoBlue,
    cartIcon,
    mobileCartIcon,
    burgerIcon,
    closeIcon,
    arrowRightIcon,
    arrowDownIcon,
    arrowUpIcon,
    frameProduct,
    calendarProduct,
  } = useStaticQuery(graphql`
    query NavImagesQuery {
      logoBlue: file(relativePath: { eq: "skylight-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      mobileLogoBlue: file(relativePath: { eq: "skylight-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cartIcon: file(relativePath: { eq: "icons/cart-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 25, layout: FIXED, placeholder: NONE)
        }
      }
      mobileCartIcon: file(relativePath: { eq: "icons/cart-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 32, layout: FIXED, placeholder: NONE)
        }
      }
      burgerIcon: file(relativePath: { eq: "icons/burger-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 28, layout: FIXED, placeholder: NONE)
        }
      }
      closeIcon: file(relativePath: { eq: "icons/close-icon.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 28, layout: FIXED, placeholder: NONE)
        }
      }
      arrowRightIcon: file(relativePath: { eq: "icons/right-arrow.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      arrowDownIcon: file(relativePath: { eq: "icons/down-arrow.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      arrowUpIcon: file(relativePath: { eq: "icons/up-arrow.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      frameProduct: file(relativePath: { eq: "nav-frame.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendarProduct: file(relativePath: { eq: "nav-cal.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)

  const RightArrow = (
    <IconContainer>
      <GatsbyImage image={arrowRightIcon.childImageSharp.gatsbyImageData} alt="Right Arrow" />
    </IconContainer>
  )
  const DownArrow = (
    <IconContainer>
      <GatsbyImage image={arrowDownIcon.childImageSharp.gatsbyImageData} alt="Down Arrow" />
    </IconContainer>
  )
  const UpArrow = (
    <IconContainer>
      <GatsbyImage image={arrowUpIcon.childImageSharp.gatsbyImageData} alt="Up Arrow" />
    </IconContainer>
  )

  const HomepageLink = (
    <HomeLink to="/">
      <LogoContainer>
        <GatsbyImage image={logoBlue.childImageSharp.gatsbyImageData} alt="Skylight Logo" />
        <GatsbyImage image={mobileLogoBlue.childImageSharp.gatsbyImageData} alt="Skylight Logo" />
      </LogoContainer>
    </HomeLink>
  )
  const FlagDropDown = () => (
    <div className="dropdown">
      <button
        type="button"
        className="countryDropbtn"
        aria-label={`${COUNTRIES[LOCALIZATION].abv} Flag`}
      >
        <Flag
          alt={`${COUNTRIES[LOCALIZATION].country} Flag`}
          className="flagShadow"
          code={COUNTRIES[LOCALIZATION].abv}
          height="20"
        />
      </button>
      <div className="country-dropdown-content">
        {Object.values(COUNTRIES).map((country, i) => {
          if (country.localization !== LOCALIZATION) {
            return (
              <NavA key={i} href={country.link} target="_blank" rel="noopener noreferrer">
                <Flag
                  alt={`${country.country} Flag`}
                  className="flagShadow"
                  code={country.abv}
                  height="18"
                />
              </NavA>
            )
          }
          return null
        })}
      </div>
    </div>
  )

  const productLinks = [
    { link: '/frame/', text: 'Frame' },
    { link: '/calendar/', text: 'Calendar' },
    { link: '/calendar-max', text: 'Cal Max' },
    { link: PLUS_BUY_LINK, text: 'Frame Plus' },
    { link: '/products/calendar-skylight-plus/', text: 'Calendar Plus' },
  ]
  const navlinks = [
    { link: supportLink, text: 'Support' },
    { link: 'https://app.ourskylight.com/download-app', text: 'Log In' },
  ]

  const productDetails = [
    { name: 'Shop Frame', image: frameProduct, link: BUY_LINK },
    { name: 'Shop Calendar', image: calendarProduct, link: CAL_BUY_LINK },
  ]
  return (
    <>
      <Container>
        {HomepageLink}
        <NavContainer>
          <NavLink to="/pages/about-us/" about="true">
            <Trans>About</Trans>
          </NavLink>
          {(inUS || IN_CA || IN_AU || IN_UK) && (
            <HideOnMobile>
              <div className="dropdown">
                <button type="button" className="dropbtn">
                  <Trans>Products</Trans>
                </button>
                <div className="dropdown-content">
                  {productLinks
                    .filter(({ text }) => !(IN_CA || IN_AU || IN_UK) || text !== 'Cal Max')
                    .map((product, idx) => (
                      <NavLink to={product.link} key={`${product.text}-${idx}`}>
                        <Trans>{product.text}</Trans>
                        {product.text === 'Cal Max' && <Badge>New</Badge>}
                      </NavLink>
                    ))}
                </div>
              </div>
            </HideOnMobile>
          )}
          {navlinks.map((link, idx) => (
            <NavA
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              key={`${link.text} - ${idx}`}
            >
              <Trans>{link.text}</Trans>
            </NavA>
          ))}
          <IconContainer onClick={() => setShowMenu(!showMenu)} noMargin={showMenu}>
            <GatsbyImage
              image={
                showMenu
                  ? closeIcon.childImageSharp.gatsbyImageData
                  : burgerIcon.childImageSharp.gatsbyImageData
              }
              alt="Menu"
            />
          </IconContainer>
          {!showMenu && (
            <>
              <FlagDropDown />
              <DesktopCartLink to="/cart/">
                <GatsbyImage image={cartIcon.childImageSharp.gatsbyImageData} alt="Shopping Cart" />
                {hasItemsInCart && <QuantityText>{numItems}</QuantityText>}
              </DesktopCartLink>
            </>
          )}
          {showMenu || !!handle ? null : (
            <ConditionalCTA
              url={!IS_FOREIGN_COUNTRY ? CAL_BUY_LINK : buyLink}
              text={t('Buy Now ')}
              analyticsEvent={buyAnalyticsEvent}
              anchorComponent={NavBuyNowAnchor}
              linkComponent={NavBuyNowLink}
              page={page}
            />
          )}
        </NavContainer>
      </Container>
      {showMenu && (
        <MobileNavLinkContainer>
          {(inUS || IN_CA || IN_AU) && (
            <ProductContainer>
              {productDetails.map((product, idx) => (
                <ProductCard index={idx} key={product.name}>
                  <GatsbyImage
                    image={product.image.childImageSharp.gatsbyImageData}
                    alt={product.name}
                  />
                  <ButtonContainer
                    display={handle === `${PLUS_PRODUCT_HANDLE}` ? 'none' : 'flex'}
                    onClick={() => setShowMenu(false)}
                  >
                    <ConditionalCTA
                      url={product.link}
                      text={t(product.name)}
                      analyticsEvent={buyAnalyticsEvent}
                      anchorComponent={NavAProduct}
                      linkComponent={MobileNavBuyNowLink}
                      page={page}
                    />
                  </ButtonContainer>
                </ProductCard>
              ))}
            </ProductContainer>
          )}
          <MobileNavLink to="/pages/about-us/" onClick={() => setShowMenu(false)}>
            <Text>
              <Trans>About</Trans>
            </Text>
            {RightArrow}
          </MobileNavLink>
          {(inUS || IN_CA || IN_AU || IN_UK) && (
            <>
              <ProductsLink onClick={() => setShowProducts(!showProducts)}>
                <Text>Products</Text>
                {showProducts ? UpArrow : DownArrow}
              </ProductsLink>
              {showProducts && (
                <>
                  {productLinks
                    .filter(({ text }) => !(IN_CA || IN_AU || IN_UK) || text !== 'Cal Max')
                    .map((product, idx) => (
                      <MobileLink
                        to={product.link}
                        onClick={() => setShowMenu(false)}
                        key={`${product.text} - ${idx}`}
                      >
                        <MobileProduct>
                          <Trans>{product.text}</Trans>
                          {product.text === 'Cal Max' && <Badge>New</Badge>}
                        </MobileProduct>
                      </MobileLink>
                    ))}
                </>
              )}
            </>
          )}
          {navlinks.map((link, idx) => (
            <MobileNavLinkA
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => setShowMenu(false)}
              key={`${link.text} - ${idx}`}
            >
              <Text>
                <Trans>{link.text}</Trans>
              </Text>
              {RightArrow}
            </MobileNavLinkA>
          ))}
          <ProductsLink onClick={() => setShowCountries(!showCountries)}>
            <Text>
              <Flag
                alt={`${COUNTRIES[LOCALIZATION].country} Flag`}
                className="flagShadow"
                code={COUNTRIES[LOCALIZATION].abv}
                height="18"
              />{' '}
              <Trans>{COUNTRIES[LOCALIZATION].country}</Trans>
            </Text>
            {showCountries ? UpArrow : DownArrow}
          </ProductsLink>
          {showCountries && (
            <>
              {Object.values(COUNTRIES).map((country, i) => {
                if (country.localization !== LOCALIZATION) {
                  return (
                    <MobileNavLinkA
                      key={i}
                      href={country.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => setShowCountries(false)}
                    >
                      <MobileProduct>
                        <Flag
                          alt={`${country.country} Flag`}
                          className="flagShadow"
                          code={country.abv}
                          width="30"
                        />
                        <Trans>{country.country}</Trans>
                      </MobileProduct>
                    </MobileNavLinkA>
                  )
                }
                return null
              })}
            </>
          )}
          <CartLink to="/cart/" onClick={() => setShowMenu(false)}>
            <MobileCartView>
              <GatsbyImage
                image={mobileCartIcon.childImageSharp.gatsbyImageData}
                alt="Shopping Cart"
              />
              {hasItemsInCart && <QuantityTextMobile>{numItems}</QuantityTextMobile>}
              <p>
                <Trans>Shopping Cart</Trans>
              </p>
            </MobileCartView>
          </CartLink>
          {!inUS && (
            <ButtonContainer
              display={handle === `${PLUS_PRODUCT_HANDLE}` ? 'none' : 'flex'}
              onClick={() => setShowMenu(false)}
            >
              <ConditionalCTA
                url={!IS_FOREIGN_COUNTRY ? CAL_BUY_LINK : buyLink}
                text={t('Buy Now ')}
                analyticsEvent={buyAnalyticsEvent}
                anchorComponent={NavA}
                linkComponent={MobileNavBuyNowLink}
                page={page}
              />
            </ButtonContainer>
          )}
        </MobileNavLinkContainer>
      )}
    </>
  )
}

Navigation.propTypes = {
  buyLink: PropTypes.string.isRequired,
  page: PropTypes.string,
  buyAnalyticsEvent: PropTypes.string,
  handle: PropTypes.string,
}

export default Navigation
