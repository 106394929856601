import styled from 'styled-components'

import { contentPadding, breakpoints } from 'src/utils/styles/global-styles'

export const Container = styled.header`
  padding: 30px 0px;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.cream};
  @media (max-width: ${breakpoints.s}px) {
    ${contentPadding}
  }
`
export const LogoContainer = styled.div`
  width: 230px;
  @media (max-width: 375px) {
    width: 110px;
  }
  @media (max-width: 340px) {
    width: 100px;
  }
`
