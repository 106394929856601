import styled from 'styled-components'
import { breakpoints, BuyNowLinkCSS } from 'src/utils/styles/global-styles'
import { MediumHeader, Text } from '../DigitalSteps/styles'

export const AndroidContainer = styled.div`
  width: 26px;
  margin: 0px 5px;
`
export const AppleContainer = styled.div`
  width: 22px;
  margin: 0px 5px;
`
export const SubmitButton = styled.button`
  ${BuyNowLinkCSS}
  padding: 12px 30px;
  margin-left: 50px;
`

export const DownloadButton = styled.button`
  ${BuyNowLinkCSS}
  display: ${(props) => (props.hideOnDesktop ? 'none' : 'inline-block')};
  padding: 12px 18px;
  margin-top: 0px;
  @media (max-width: ${breakpoints.s}px) {
    display: inline-block;
  }
`

export const TextBox = styled.div`
  margin-left: 5%;
  margin-right: 60px;
  max-width: ${(props) => (props.albums ? '500px' : '100%')};
  @media (max-width: ${breakpoints.xl}px) {
    margin: 45px auto 0px;
    max-width: 90%;
  }
  @media (max-width: ${breakpoints.s}px) {
    margin: 25px auto -30px;
    text-align: center;
  }
`
export const PhoneInput = styled.form`
  input {
    width: 250px;
    font-family: 'FilsonProThin';
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.15px;
    background-color: ${(props) => props.theme.cream};
    outline: none;
    border-color: ${(props) => props.theme.cream};
    border-width: 0px;
    border-bottom: 1px solid black;
    padding-bottom: 7px;
  }
  margin-bottom: 20px;
`
export const Header = styled(MediumHeader)`
  margin-bottom: 0px;
  text-align: ${(props) => (props.albums ? 'center' : 'left')};
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
`
export const Description = styled(Text)`
  width: 470px;
  margin-top: 20px;
  text-align: ${(props) => (props.albums ? 'center' : 'left')};
  margin: ${(props) => (props.albums ? '20px auto 40px' : '20px 0px 40px')};
  @media (max-width: ${breakpoints.m}px) {
    text-align: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'inline-block')};
    margin: 15px auto 20px;
    width: 100%;
  }
  @media (max-width: 375px) {
    width: 300px;
    max-width: 100%;
  }
`
export const FormRow = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: ${breakpoints.xl}px) {
    justify-content: ${(props) => (props.albums ? 'center' : 'left')};
  }
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
export const LogoRow = styled.div`
  display: flex;
  @media (max-width: ${breakpoints.xl}px) {
    justify-content: ${(props) => (props.albums ? 'center' : 'left')};
  }
  @media (max-width: ${breakpoints.s}px) {
    display: none;
  }
`
export const DigitalButton = styled.button`
  border: none;
  background-color: transparent;
  display: contents;
  &:hover {
    cursor: pointer;
  }
`
export const InstallButton = styled.button`
  border: none;
  font-family: 'FilsonPro';
  text-decoration: none;
  color: white;
  font-weight: bold;
  padding: 4px 12px;
  background-color: #54b289;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.625;
`
export const FireLogoContainer = styled.div`
  width: 60px;
  margin: 0px 7px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 50px;
    padding-top: 10px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 40px;
    padding-top: 5px;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding-top: 0px;
  }
`
export const FlexBox = styled.div`
  display: flex;
  margin: 0px;
  @media (max-width: ${breakpoints.m}px) {
    justify-content: center;
    text-align: center;
  }
`
