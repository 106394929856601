import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'
import { isMobileOnly, isAndroid } from 'react-device-detect'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import BasicNavigation from '../BasicNavigation'
import { HomeLink } from '../Navigation/styles'
import { InstallButton } from '../DigitalForm/styles'

const MobilePromoBanner = () => {
  const [showMobilePromoBanner, setShowMobilePromoBanner] = useState(isMobileOnly)
  const { appLogo } = useStaticQuery(graphql`
    query MobilePromoBannerQuery {
      appLogo: file(relativePath: { eq: "digital/icon-192.webp" }) {
        childImageSharp {
          gatsbyImageData(width: 40, layout: FIXED, placeholder: NONE)
        }
      }
    }
  `)
  const handleRedirectDigitalDeepLink = async (e) => {
    e.preventDefault()
    Analytics.track(`Added Digital to Cart`)
    window.location.href = 'https://skylightfamily.page.link/digital'
  }
  if (!showMobilePromoBanner) return <BasicNavigation />
  return (
    <Container>
      <MainContent>
        <MdClose size="18" onClick={() => setShowMobilePromoBanner(false)} />
        <HomeLink to="/">
          <GatsbyImage image={appLogo.childImageSharp.gatsbyImageData} alt="Skylight App Logo" />
        </HomeLink>
        <TextContent>
          <Header>Skylight App</Header>
          <Subheader>Free on the {isAndroid ? 'Play' : 'App'} store</Subheader>
        </TextContent>
      </MainContent>
      <InstallButton type="button" onClick={handleRedirectDigitalDeepLink}>
        Install
      </InstallButton>
    </Container>
  )
}

export default MobilePromoBanner

const Container = styled.div`
  background: #f2f1f5;
  margin: 0px 0px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const MainContent = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 1rem;
    &:hover,
    &:active {
      opacity: 0.7;
    }
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.75rem;
`

const Header = styled.h4`
  font-family: 'FilsonPro';
  font-weight: bold;
  font-size: 0.95rem;
  margin: 0;
`

const Subheader = styled.h4`
  font-family: 'FilsonProBook';
  font-size: 0.95rem;
  margin: 0;
`
