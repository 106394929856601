import styled from 'styled-components'
import { breakpoints, BigHeader } from 'src/utils/styles/global-styles'

export const Container = styled.div`
  max-width: 90%;
  margin: auto;
  padding-bottom: 60px;
  @media (max-width: ${breakpoints.m}px) {
    padding-bottom: 10px;
  }
`
export const MediumHeader = styled.h2`
  font-family: 'P22MackinacProMedium';
  color: ${(props) => (props.blue ? '#2178af' : '#444444')};
  font-size: 34px;
  line-height: 50px;
  margin: 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
    line-height: 47px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.15px;
    margin: 3px 0px;
  }
`
export const AppImageContainer = styled.div`
  width: 450px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 350px;
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 0px;
  flex-direction: row;
  @media (max-width: ${breakpoints.l}px) {
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : 'column')};
    justify-content: center;
  }
  @media (max-width: ${breakpoints.s}px) {
    padding: 15px 0px;
  }
`
export const CenteredRow = styled.div`
  background-color: ${(props) => (props.blue ? '#dcf1f3' : '#FFFEF9')};
  padding: ${(props) => (props.blue ? '20px 0px 40px' : '10px 0px 80px')};
  text-align: center;
  width: 100%;
  h1 {
    text-align: center;
  }
  p {
    width: 630px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.xl}px) {
    padding: ${(props) => (props.blue ? '20px 0px 40px' : '0px 0px 40px')};
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: ${(props) => (props.blue ? '30px 0px 50px' : '0px 0px 20px')};
    p {
      width: ${(props) => (props.blue ? '90%' : '100%')};
    }
  }
`
export const Textbox = styled.div`
  margin: auto;
  width: 420px;
  text-align: center;
  @media (max-width: ${breakpoints.l}px) {
    padding-top: 40px;
  }
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`
export const TvImageContainer = styled.div`
  width: 600px;
  @media (max-width: ${breakpoints.xl}px) {
    width: 450px;
    margin: auto;
  }
  @media (max-width: ${breakpoints.s}px) {
    width: 100%;
  }
`
export const Header = styled(BigHeader)`
  font-family: 'P22MackinacProMedium';
  margin: 20px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 32px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 0.15px;
  }
`
export const Text = styled.p`
  font-family: 'FilsonProLight';
  font-size: 24px;
  line-height: 31px;
  letter-spacing: 0.09px;
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
  @media (max-width: ${breakpoints.s}px) {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.09px;
    margin-top: 15px;
  }
`
