/* eslint-disable no-shadow */
import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Client from 'shopify-buy'

import ABTestGroup from 'src/utils/ABTestGroup'
import AmplitudeDeviceID from 'src/utils/AmplitudeDeviceID'
import Context from '../context/StoreContext'
import UTMParams from '../utils/UTMParams'

// For recharge subscription billing
const CHARGE_INTERVAL_UNITS = 'day'
const CHARGE_INTERVAL_FREQ = 365

const client = Client.buildClient({
  storefrontAccessToken: `${process.env.GATSBY_SHOPIFY_ACCESS_TOKEN}`,
  domain: process.env.GATSBY_SHOPIFY_STORE_DOMAIN || process.env.GATSBY_SHOPIFY_STORE_NAME,
})

const ContextProvider = ({ children }) => {
  const initialStoreState = {
    client,
    adding: false,
    loading: true,
    checkout: { lineItems: [], discountApplications: [] },
    banner: { promoCode: '', channel: '', amount: '' },
  }
  const [store, updateStore] = useState(initialStoreState)

  const updateBanner = useCallback((promoCode, channel, amount) => {
    localStorage.setItem('banner', JSON.stringify({ promoCode, channel, amount }))
    updateStore((prevState) => ({
      ...prevState,
      banner: { promoCode, channel, amount },
    }))
  }, [])

  const checkAndApplyExistingBanner = useCallback(() => {
    const existingBanner = JSON.parse(localStorage.getItem('banner'))
    if (existingBanner) {
      const { promoCode, channel, amount } = existingBanner
      updateBanner(promoCode, channel, amount)
    }
  }, [updateBanner])

  const initializeCheckout = useCallback(async () => {
    // check for existing checkout
    const isBrowser = typeof window !== 'undefined'
    const existingCheckoutId = isBrowser ? localStorage.getItem('shopify_checkout_id') : null
    // function to persist checkout info
    const setCheckoutInState = (checkout) => {
      if (isBrowser) {
        localStorage.setItem('shopify_checkout_id', checkout.id)
      }
      updateStore((prevState) => ({ ...prevState, checkout, loading: false }))
      return checkout
    }

    const createNewCheckout = async () => store.client.checkout.create()
    const fetchCheckout = async (id) => store.client.checkout.fetch(id)

    const generateCustomAttrs = (callback) => {
      const customAttributes = []
      if (ABTestGroup.current()) {
        customAttributes.push({ key: 'ABTestGroup', value: ABTestGroup.current() })
      }
      const utmParams = UTMParams.get()
      Object.keys(utmParams).forEach((key) => {
        customAttributes.push({ key, value: utmParams[key] })
      })
      AmplitudeDeviceID.get((amplitudeDeviceID) => {
        if (amplitudeDeviceID) {
          customAttributes.push({ key: 'AmplitudeDeviceId', value: amplitudeDeviceID })
        }
        callback(customAttributes)
      })
    }

    if (existingCheckoutId) {
      try {
        const checkout = await fetchCheckout(existingCheckoutId)
        // Make sure this cart hasn’t already been purchased.
        if (!checkout.completedAt) {
          return setCheckoutInState(checkout)
        }
      } catch (e) {
        localStorage.setItem('shopify_checkout_id', null)
      }
    }

    const newCheckout = await createNewCheckout()
    generateCustomAttrs((customAttributes) => {
      client.checkout.updateAttributes(newCheckout.id, { customAttributes })
    })
    return setCheckoutInState(newCheckout)
  }, [store.client.checkout])

  useEffect(() => {
    initializeCheckout()
    checkAndApplyExistingBanner()
  }, [initializeCheckout, checkAndApplyExistingBanner])

  const applyDiscount = useCallback(async (checkoutId, discountCode) => {
    if (!checkoutId || !discountCode) return
    const updatedCheckout = await client.checkout.addDiscount(checkoutId, discountCode)
    updateStore((prevState) => ({ ...prevState, checkout: updatedCheckout }))
  }, [])

  const removeDiscount = useCallback(async (checkoutId) => {
    const updatedCheckout = await client.checkout.removeDiscount(checkoutId)
    updateStore((prevState) => ({ ...prevState, checkout: updatedCheckout }))
  }, [])

  // Shopify cart functions
  const addToCart = async (lineItems) => {
    let error = null
    lineItems.forEach(({ variantId, quantity }) => {
      if (!variantId) error = 'Please select a product.'
      if (!quantity) error = 'Please input a quantity greater than 0'
    })
    if (error) return { error }
    updateStore((prevState) => ({ ...prevState, adding: true }))

    let { checkout, client } = store
    const {
      banner: { promoCode },
    } = store
    if (!checkout.id) {
      checkout = await initializeCheckout()
    }
    const checkoutId = checkout.id
    const lineItemsToUpdate = lineItems.map(({ variantId, quantity }) => ({
      variantId,
      quantity: parseInt(quantity, 10),
    }))

    const updatedCheckout = await client.checkout.addLineItems(checkoutId, lineItemsToUpdate)
    await updateStore((prevState) => ({ ...prevState, checkout: updatedCheckout, adding: false }))
    if (promoCode) {
      applyDiscount(updatedCheckout.id, store.banner.promoCode)
    }
  }

  const removeLineItem = async (client, checkoutId, lineItemId) => {
    const updatedCheckout = await client.checkout.removeLineItems(checkoutId, [lineItemId])
    updateStore((prevState) => ({ ...prevState, checkout: updatedCheckout }))
    return updatedCheckout
  }

  const updateLineItem = async (client, checkoutId, lineItemId, quantity) => {
    const lineItemsToUpdate = [{ id: lineItemId, quantity: parseInt(quantity, 10) }]

    const updatedCheckout = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate)
    updateStore((prevState) => ({ ...prevState, checkout: updatedCheckout }))
    return updatedCheckout
  }

  const isSubscriptionItem = (item) =>
    /plus/i.test(item.title) || /tv/i.test(item.title) || /digital/i.test(item.title)

  // Recharge checkout functions
  const isUserBuyingPlus = (checkout) => {
    let buyingPlus = false
    checkout.lineItems.forEach((item) => {
      if (isSubscriptionItem(item)) buyingPlus = true
    })
    return buyingPlus
  }

  const convertShopifyCartToRecharge = () => {
    const {
      checkout: { lineItems, discountApplications: [{ code } = {}] = [] },
    } = store
    const rechargeData = {}
    rechargeData.discount_code = code
    rechargeData.note_attributes = [{ name: 'ABTestGroup', value: ABTestGroup.current() }]
    const utmParams = UTMParams.get()
    Object.keys(utmParams).forEach((name) =>
      rechargeData.note_attributes.push({ name, value: utmParams[name] })
    )
    rechargeData.line_items = lineItems.map((item) => {
      const itemCopy = { ...item }
      /* The Storefront GraphQL API provides an encoded variant ID.
       * To get create a recharge checkout, we need a decoded version.
       * atob() is a method available on the window obejct that does the decoding.
       */
      const storeFrontVariantId = itemCopy.variant.id
      const parsedVariantId = storeFrontVariantId.split('/')[4]

      itemCopy.variant_id = parsedVariantId
      if (isSubscriptionItem(item)) {
        itemCopy.charge_interval_frequency = CHARGE_INTERVAL_FREQ
        itemCopy.order_interval_frequency = CHARGE_INTERVAL_FREQ
        itemCopy.order_interval_unit = CHARGE_INTERVAL_UNITS
      }
      return itemCopy
    })
    return rechargeData
  }

  const initializeRechargeCheckout = async () => {
    const checkoutData = convertShopifyCartToRecharge()
    const rechargeCheckout = await axios.post(
      '/.netlify/functions/createRechargeCheckout',
      checkoutData
    )
    return rechargeCheckout.data
  }

  return (
    <Context.Provider
      value={{
        store,
        addToCart,
        removeLineItem,
        updateLineItem,
        applyDiscount,
        initializeRechargeCheckout,
        isUserBuyingPlus,
        updateBanner,
        removeDiscount,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ContextProvider

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
